import Image from "next/image";
import { useCallback, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { validateField } from '../../../../lib/weddingWebsiteValidations';

import { getImageSrc, handleCropComplete, handleFileSelect } from '../../../../lib/common';
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import WbEditButton from "./shared/WbEditButton";
import WbInput from "./shared/WbInput";
import WbRemoveButton from "./shared/WbRemoveButton";
import WbTextarea from "./shared/WbTextarea";
import WbUpload from "./shared/WbUpload";
import ImagePopup from "./ImagePopup";
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbAddPerson({ onDeleteClick, name, role, description, image, onNameChange, onRoleChange, onDescriptionChange, onImageUpload, handleImageRemove, showValidation = false }) {
  const [localName, setLocalName] = useState(name);
  const [localRole, setLocalRole] = useState(role);
  const [localDescription, setLocalDescription] = useState(description);
  const [selectedImage, setSelectedImage] = useState(getImageSrc(image));
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  }, [localName, localRole, showValidation]);

  function validateAllFields() {
    const newErrors = {
      name: validateField('name', localName, 'Person'),
      role: validateField('role', localRole, 'Person'),
    };
    setErrors(newErrors);
  }

  const handleBlur = (value, onChangeFunc, field) => {
    onChangeFunc(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Person') });
  };

  const handleCropCompleteWrapper = croppedImage => {
    handleCropComplete(croppedImage, onImageUpload, setSelectedImage, setShowImagePopup);
  };

  const handleFileSelectWrapper = useCallback(event => {
    handleFileSelect(event, setSelectedImage, setShowImagePopup);
  }, []);

  return (
    <SidepanelEditorCard title="Add Person"  hideArrow>
      <div className="wb-person-view">
        {selectedImage ? (
          <div className="wb-person-photo-wrp">
            <div className="wb-Edit-photo-sec">
              <Image
                src={selectedImage}
                alt=""
                width={110}
                height={110}
                objectFit="cover"
              />
              <div className="wb-edit-remove-button-wrap">
                <WbEditButton
                  buttonText="Edit Photo"
                  onClick={() => setShowImagePopup(true)}
                />
                <WbRemoveButton buttonText="Remove" onClick={handleImageRemove} />
              </div>
            </div>
          </div>
        ) : (
          <WbUpload
            onFileUpload={handleFileSelectWrapper}
          />
        )}
        <div className="wb-person-details">
          <WbInput
            label="Name"
            value={localName}
            onChange={e => setLocalName(e.target.value)}
            onBlur={e => handleBlur(e.target.value, onNameChange, 'name')}
            error={errors.name}
          />
          {showValidation && <WbErrorMessage message={errors.name} />}
          <WbInput
            label="Role"
            value={localRole}
            onChange={e => setLocalRole(e.target.value)}
            onBlur={e => handleBlur(e.target.value, onRoleChange, 'role')}
            error={errors.role}
          />
          {showValidation && <WbErrorMessage message={errors.role} />}
          <WbTextarea
            label="Tell your story here..."
            value={localDescription}
            onChange={e => setLocalDescription(e.target.value)}
            onBlur={e => handleBlur(e.target.value, onDescriptionChange, 'description')}
          />
          <WbDeletewithText
          text="Delete Add Person"
          className="ml-auto"
          onClick={() => onDeleteClick()}
        />
        </div>
      </div>
      {showImagePopup && (
        <ImagePopup
          imageSrc={selectedImage}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropCompleteWrapper}
        />
      )}
    </SidepanelEditorCard>
  );
}

WbAddPerson.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  onNameChange: PropTypes.func.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  handleImageRemove: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default WbAddPerson;
