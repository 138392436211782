

function TimeLineDivider() {
    return (
      <div className="wb-time-line-divider">
       <svg width="73" height="222" viewBox="0 0 73 222" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="37.25" y1="-2.18557e-08" x2="37.25" y2="80" stroke="url(#paint0_linear_912_251)"/>
        <path d="M15.75 111C15.75 99.402 25.152 90 36.75 90C48.348 90 57.75 99.402 57.75 111C57.75 122.598 48.348 132 36.75 132C25.152 132 15.75 122.598 15.75 111Z" fill="#33434A"/>
        <g clipPath="url(#clip0_912_251)">
        <path d="M45.7501 111.001C45.7504 111.132 45.7214 111.262 45.6649 111.383C45.6084 111.504 45.5254 111.615 45.4208 111.707L39.6668 116.825C38.8933 117.512 37.8441 117.898 36.7501 117.898C35.6561 117.898 34.6069 117.512 33.8333 116.825L28.0793 111.71C27.8684 111.522 27.7499 111.268 27.75 111.003C27.7501 110.737 27.8687 110.483 28.0797 110.296C28.2908 110.108 28.577 110.003 28.8754 110.003C29.1738 110.003 29.4599 110.108 29.6708 110.296L35.4241 115.411C35.5982 115.565 35.8049 115.688 36.0325 115.772C36.26 115.856 36.5038 115.899 36.7501 115.899C36.9964 115.899 37.2402 115.856 37.4677 115.772C37.6953 115.688 37.902 115.565 38.0761 115.411L43.8293 110.296C43.9865 110.156 44.1867 110.061 44.4047 110.022C44.6228 109.983 44.8488 110.003 45.0543 110.078C45.2598 110.154 45.4356 110.282 45.5594 110.446C45.6833 110.61 45.7496 110.803 45.7501 111.001Z" fill="white"/>
        <path d="M45.7474 104.639C45.7475 104.77 45.7185 104.9 45.662 105.021C45.6055 105.143 45.5226 105.253 45.4182 105.346L38.6037 111.4C38.1109 111.837 37.4434 112.082 36.7474 112.082C36.0515 112.082 35.384 111.837 34.8912 111.4L28.0767 105.346C27.8755 105.157 27.7654 104.905 27.7697 104.645C27.7739 104.384 27.8923 104.136 28.0996 103.952C28.3069 103.768 28.5868 103.663 28.8797 103.659C29.1726 103.656 29.4555 103.754 29.6682 103.933L36.4819 109.991C36.5168 110.022 36.5582 110.046 36.6037 110.063C36.6493 110.08 36.6981 110.089 36.7474 110.089C36.7968 110.089 36.8456 110.08 36.8912 110.063C36.9367 110.046 36.9781 110.022 37.0129 109.991L43.8267 103.933C43.9839 103.793 44.1842 103.698 44.4023 103.659C44.6204 103.621 44.8464 103.64 45.052 103.716C45.2575 103.791 45.4333 103.919 45.5571 104.084C45.6808 104.248 45.7471 104.441 45.7474 104.639Z" fill="white"/>
        </g>
        <line x1="36.25" y1="222" x2="36.25" y2="142" stroke="url(#paint1_linear_912_251)"/>
        <defs>
        <linearGradient id="paint0_linear_912_251" x1="36.25" y1="2.18555e-08" x2="36.25" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BFA0AE" stopOpacity="0"/>
        <stop offset="1" stopColor="#33434A"/>
        </linearGradient>
        <linearGradient id="paint1_linear_912_251" x1="37.25" y1="222" x2="37.25" y2="142" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BFA0AE" stopOpacity="0"/>
        <stop offset="1" stopColor="#33434A"/>
        </linearGradient>
        <clipPath id="clip0_912_251">
        <rect width="16" height="18" fill="white" transform="translate(27.75 119) rotate(-90)"/>
        </clipPath>
        </defs>
        </svg>

      </div>
    );
  }
  export default TimeLineDivider;
