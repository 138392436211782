import React from 'react'

export function CropLandscapeRectangle() {
    return (
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11" y="19.5" width="10" height="19" rx="0.5" transform="rotate(-180 11 19.5)" fill="#DCDCDC"/>
        <rect x="11" y="19.5" width="10" height="19" rx="0.5" transform="rotate(-180 11 19.5)" stroke="#BDBDBD"/>
        </svg>


    )
}

export function CropPortraitRectangle() {
    return (
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="1" width="10" height="19" rx="0.5" transform="rotate(90 20 1)" fill="#DCDCDC" />
            <rect x="20" y="1" width="10" height="19" rx="0.5" transform="rotate(90 20 1)" stroke="#BDBDBD" />
        </svg>

    )
}

export function CropSquare() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="15" y="1" width="14" height="14" rx="0.5" transform="rotate(90 15 1)" fill="#DCDCDC" />
            <rect x="15" y="1" width="14" height="14" rx="0.5" transform="rotate(90 15 1)" stroke="#BDBDBD" />
        </svg>

    )
}

