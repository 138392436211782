import React from "react";
import PropTypes from "prop-types";

import DeleteIcon from "../../../../../components/Icons/DeleteIcon";

export function WbDeletewithText({ text = "delete", className, onClick }) {
  return (
    <button
      className={`wb-delete-with-text-btn ${className}`}
      onClick={onClick}
    >
      <DeleteIcon />
      <p>{text}</p>
    </button>
  );
}

WbDeletewithText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function WbDeleteButton({ className, onDeleteClick }) {
  return (
    <button
      className={`wb-delete-round-btn ${className}`}
      onClick={onDeleteClick}
    >
      <DeleteIcon />
    </button>
  );
}

WbDeleteButton.propTypes = {
  className: PropTypes.string,
  onDeleteClick: PropTypes.func,
};

export default WbDeleteButton;
