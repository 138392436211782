import PropTypes from "prop-types";
import EditIcon from "../../../../../components/Icons/EditIcon";


const WbEditButton = (props) => {
  return (
    <button className="wb-edit-btn wb-secondary-btn" onClick={props.onClick}>
       <p>{props.buttonText}</p>
    </button>
  );
};

WbEditButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default WbEditButton;



export const WbEditButtonWithoutText = (props) => {
  return (
    <button className="wb-edit-without-text-btn " onClick={props.onEditClick}>
      <EditIcon />
    </button>
  );
};

WbEditButtonWithoutText.propTypes = {
  onEditClick: PropTypes.func,
};

WbEditButtonWithoutText.propTypes = {
  onEditClick: PropTypes.func,
};

