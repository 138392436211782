import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { validateField } from "../../../../lib/weddingWebsiteValidations";
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbInput from "./shared/WbInput";
import WbTextarea from "./shared/WbTextarea";
import WbErrorMessage from "./shared/WbErrorMessage"

function WbTextBlock({
  onDeleteClick,
  showValidation = false,
  title = "Text Block",
  titleText,
  onTitleChange,
  bodyText,
  onBodyChange,
}) {
  const [localTitleText, setLocalTitleText] = useState(titleText)
  const [localBodyText, setLocalBodyText] = useState(bodyText)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    validateAllFields()
  }, [localTitleText, localBodyText, showValidation])

  function validateAllFields() {
    const newErrors = {
      titleText: validateField('titleText', localTitleText, 'TextBlock'),
      bodyText: validateField('bodyText', localBodyText, 'TextBlock')
    }
    setErrors(newErrors)
  }

  const handleBlur = (value, onChangeFunc, field) => {
    onChangeFunc(value);
    setErrors({ ...errors, [field]: validateField(field, value, "TextBlock") });
  };

  return (
    <SidepanelEditorCard title={title} hideArrow>
      <div className="wb-text-editor-wrap">
        <WbInput
          label="Title Text"
          value={localTitleText}
          onChange={e => setLocalTitleText(e.target.value)}
          onBlur={e => handleBlur(e.target.value, onTitleChange, "titleText")}
          error={errors.titleText}
        />
        {showValidation && <WbErrorMessage message={errors.titleText} />}
        <div className='wb-text-editor-block'>
          <WbTextarea
            label="Body Text"
            value={localBodyText}
            onChange={e => setLocalBodyText(e.target.value)}
            onBlur={e => handleBlur(e.target.value, onBodyChange, "bodyText")}
            error={errors.bodyText}
          />
          {showValidation && <WbErrorMessage message={errors.bodyText} />}
          <WbDeletewithText
            text={`Delete ${title}`}
            className="ml-auto"
            onClick={() => onDeleteClick()}
          />
        </div>
      </div>
    </SidepanelEditorCard>
  );
}

WbTextBlock.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  bodyText: PropTypes.string.isRequired,
  onBodyChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default WbTextBlock;
