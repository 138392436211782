import PropTypes from 'prop-types';

function WbErrorMessage({ message }) {
  if (!message) return null;

  return <p className="wb-error-message">{message}</p>;
}

WbErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default WbErrorMessage;
