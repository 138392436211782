import PropTypes from "prop-types";
import ImageIcon from "../../../../../components/Icons/ImageIcon";
import { handleFileUpload } from '../../../../../lib/common';

function WbDragAndDropUpload({
  onFileUpload,
  multiple = true,
  accept = "image/jpeg, image/png, image/webp",
}) {
  return (
    <div
      className="photo-upload wb-form-field"
      onDrop={e => {
        e.preventDefault();
        handleFileUpload(e.dataTransfer.files, accept, onFileUpload);
      }}
      onDragOver={e => e.preventDefault()}
    >
      <input
        type="file"
        className="photo-input form-control"
        accept={accept}
        multiple={multiple}
        onChange={e => handleFileUpload(e.target.files, accept, onFileUpload)}
      />
      <button className="upload-button">
        <ImageIcon />
        <span>
          Click to Select Photos
          <br />
          or Drag Them Here
        </span>
      </button>
    </div>
  );
}

WbDragAndDropUpload.propTypes = {
  onFileUpload: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

export default WbDragAndDropUpload;
