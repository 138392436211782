import PropTypes from "prop-types";

import ArrowRight from "../../../../../components/Icons/ArrowRight";
import { WbDeletewithText } from './WbDeleteButton';

function SidepanelEditorCard({
  title = "",
  children,
  buttons,
  onClick,
  onCancelClick,
  onUpdateClick,
  onDeleteClick,
  hideArrow = false,
}) {
  return (
    <div className="side-panel-editor">
      <div className="side-panel-head">
        <button className="side-panel-back-btn" onClick={onClick}>
          {hideArrow ? '' : <ArrowRight />}
          {title}{" "}
        </button>
        {onDeleteClick && <WbDeletewithText text="" onClick={onDeleteClick} />}
      </div>

      {children}

      {buttons && (
        <div className="wb-action-btns">
          <button
            className="wbBtn wb-borderBtn ml-auto"
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <button className="wbBtn wb-bgBtn" onClick={onUpdateClick}>
            Update
          </button>
        </div>
      )}
    </div>
  );
}

SidepanelEditorCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.bool,
  onClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onUpdateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  hideArrow: PropTypes.bool,
};

export default SidepanelEditorCard;
