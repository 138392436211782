import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import SidepanelEditorCard from './shared/SidepanelEditorCard'
import WbInput from './shared/WbInput'
import WbRadio from './shared/WbRadio'
import { WbDeletewithText } from "./shared/WbDeleteButton"
import { validateField } from '../../../../lib/weddingWebsiteValidations'
import WbErrorMessage from './shared/WbErrorMessage'

export const SMALL = 'small'
export const LARGE = 'large'

function WbAddTitle({
  titleType = SMALL,
  onTitleTypeChange,
  titleText = '',
  onTitleTextChange,
  onDeleteClick,
  showValidation = false,
}) {
  const [localTitleText, setLocalTitleText] = useState(titleText)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    validateAllFields()
  }, [localTitleText, showValidation])

  function validateAllFields() {
    const newErrors = {
      titleText: validateField('titleText', localTitleText, 'Title')
    }
    setErrors(newErrors)
  }

  const handleBlur = (value) => {
    onTitleTextChange(value)
    setErrors({ ...errors, titleText: validateField('titleText', value, 'Title') })
  }

  function handleChange(e) {
    const newValue = e.target.value
    setLocalTitleText(newValue)
    onTitleTypeChange(titleType)
  }

  return (
    <SidepanelEditorCard title="Title" hideArrow>
      <div className='editor-title-wrap'>
        <p>Title Type</p>
        <WbRadio
          text='Small Title'
          className='wb-radio-sm'
          checked={titleType === SMALL}
          onChange={() => onTitleTypeChange(SMALL)}
        />
        <WbRadio
          text='Large Title'
          className='wb-radio-lg'
          checked={titleType === LARGE}
          onChange={() => onTitleTypeChange(LARGE)}
        />
      </div>

      <WbInput
        label="Title Text"
        value={localTitleText}
        onChange={handleChange}
        onBlur={e => handleBlur(e.target.value)}
        error={errors.titleText}
      />
      {showValidation && <WbErrorMessage message={errors.titleText} />}

      <WbDeletewithText
        text="Delete Title Type"
        className="ml-auto"
        onClick={() => onDeleteClick()}
      />
    </SidepanelEditorCard>
  )
}

WbAddTitle.propTypes = {
  onDeleteClick: PropTypes.func,
  titleType: PropTypes.oneOf([SMALL, LARGE]).isRequired,
  onTitleTypeChange: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  onTitleTextChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default WbAddTitle