import Image from "next/image";
import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";

import { getImageSrc, handleCropComplete, handleFileSelect } from '../../../../lib/common';
import { validateField } from '../../../../lib/weddingWebsiteValidations';
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import WbEditButton from "./shared/WbEditButton";
import WbInput from "./shared/WbInput";
import WbRemoveButton from "./shared/WbRemoveButton";
import WbTextarea from "./shared/WbTextarea";
import WbUpload from "./shared/WbUpload";
import ImagePopup from "./ImagePopup";
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbAddStory({
  onDeleteClick,
  showValidation = false,
  title,
  onTitleChange,
  description,
  onDescriptionChange,
  onImageUpload,
  onImageRemove,
  image,
}) {
  const [selectedImage, setSelectedImage] = useState(getImageSrc(image));
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);
  const [localDescription, setLocalDescription] = useState(description);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  }, [localTitle, localDescription, showValidation]);

  function validateAllFields() {
    const newErrors = {
      title: validateField('title', localTitle, 'Story'),
      description: validateField('description', localDescription, 'Story')
    };
    setErrors(newErrors);
  }

  const handleBlur = (value, onChangeFunc, field) => {
    onChangeFunc(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Story') });
  };

  const handleCropCompleteWrapper = croppedImage => {
    handleCropComplete(croppedImage, onImageUpload, setSelectedImage, setShowImagePopup);
  };

  const handleFileSelectWrapper = useCallback(event => {
    handleFileSelect(event, setSelectedImage, setShowImagePopup);
  }, []);

  const handleImageRemove = () => {
    onImageRemove();
    setSelectedImage(null);
  };

  const handleTitleChangeWrapper = (value) => {
    setLocalTitle(value);
    onTitleChange(value);
  };

  const handleDescriptionChangeWrapper = (value) => {
    setLocalDescription(value);
    onDescriptionChange(value);
  };

  return (
    <SidepanelEditorCard
      title="Add Story"
      hideArrow
    >
      {selectedImage ? (
        <div className="wb-add-story-wrp">
          <div className="wb-Edit-photo-sec">
            <div>
              <Image src={selectedImage} alt="" width={110} height={110} objectFit="cover" />
            </div>
            <div className="wb-edit-remove-button-wrap">
              <WbEditButton
                buttonText="Edit Photo"
                onClick={() => setShowImagePopup(true)}
              />
              <WbRemoveButton buttonText="Remove" onClick={handleImageRemove} />
            </div>
          </div>
        </div>
      ) : (
        <WbUpload
          onFileUpload={handleFileSelectWrapper}
        />
      )}

      <div className="wb-add-story-wrp">
        <WbInput
          label="Title"
          value={localTitle}
          onChange={e => setLocalTitle(e.target.value)}
          onBlur={e => handleBlur(e.target.value, onTitleChange, 'title')}
          error={errors.title}
        />
        {showValidation && <WbErrorMessage message={errors.title} />}

        <WbTextarea
          label="Tell your story"
          value={localDescription}
          onChange={e => setLocalDescription(e.target.value)}
          onBlur={e => handleBlur(e.target.value, onDescriptionChange, 'description')}
          error={errors.description}
        />
        {showValidation && <WbErrorMessage message={errors.description} />}

        <WbDeletewithText
          text="Delete Story"
          className="ml-auto"
          onClick={() => onDeleteClick()}
        />
      </div>

      {showImagePopup && (
        <ImagePopup
          imageSrc={selectedImage}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropCompleteWrapper}
        />
      )}
    </SidepanelEditorCard>
  );
}

WbAddStory.propTypes = {
  onDeleteClick: PropTypes.func,
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  description: PropTypes.string,
  onDescriptionChange: PropTypes.func,
  onImageUpload: PropTypes.func,
  onImageRemove: PropTypes.func,
  image: PropTypes.string,
  showValidation: PropTypes.bool,
};

export default WbAddStory;
