import React, { useCallback, useState, useEffect } from "react";
import Image from "next/image";
import PropTypes from 'prop-types';

import { getImageSrc, handleCropComplete, handleFileSelect } from "../../../../lib/common";
import { validateField } from '../../../../lib/weddingWebsiteValidations';
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import WbDateTimePicker from "./shared/WbDateTimePicker";
import WbEditButton from "./shared/WbEditButton";
import WbInput from "./shared/WbInput";
import WbRemoveButton from "./shared/WbRemoveButton";
import WbTextarea from "./shared/WbTextarea";
import WbUpload from "./shared/WbUpload";
import ImagePopup from './ImagePopup';
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbAddHotel({
  address,
  blockCode,
  checkInDate,
  checkOutDate,
  details,
  email,
  image,
  name,
  phone,
  roomRate,
  website,
  onAddressChange,
  onBlockCodeChange,
  onCheckInDateChange,
  onCheckOutDateChange,
  onDeleteClick,
  onDetailsChange,
  onEmailChange,
  onImageRemove,
  onImageUpload,
  onNameChange,
  onPhoneChange,
  onRoomRateChange,
  onWebsiteChange,
  showValidation = false,
}) {
  const [selectedImage, setSelectedImage] = useState(getImageSrc(image));
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  }, [address, blockCode, checkInDate, checkOutDate, details, email, image, name, phone, roomRate, website, showValidation]);

  function validateAllFields() {
    const newErrors = {
      name: validateField('name', name, 'Hotel'),

    };
    setErrors(newErrors);
  }

  function handleCropCompleteWrapper(croppedImage) {
    handleCropComplete(
      croppedImage,
      (newImage) => {
        onImageUpload(newImage);
        setErrors({ ...errors, image: validateField('image', newImage, 'Hotel') });
      },
      setSelectedImage,
      setShowImagePopup
    );
  }

  const handleFileSelectWrapper = useCallback(event => {
    handleFileSelect(event, setSelectedImage, setShowImagePopup);
  }, []);

  function handleImageRemove() {
    onImageRemove();
    setSelectedImage(null);
    setErrors({ ...errors, image: validateField('image', null, 'Hotel') });
  }

  function handleInputChange(field, value) {
    const updateFunction = {
      name: onNameChange,
      address: onAddressChange,
      phone: onPhoneChange,
      email: onEmailChange,
      website: onWebsiteChange,
      blockCode: onBlockCodeChange,
      roomRate: onRoomRateChange,
      details: onDetailsChange,
    }[field];

    updateFunction(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Hotel') });
  }

  function handleDateChange(field, value) {
    const updateFunction = {
      checkInDate: onCheckInDateChange,
      checkOutDate: onCheckOutDateChange,
    }[field];

    updateFunction(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Hotel') });
  }

  return (
    <SidepanelEditorCard title="Hotel" onDeleteClick={onDeleteClick} hideArrow>
      <WbInput
        label="Name"
        value={name}
        onChange={e => handleInputChange('name', e.target.value)}
        error={errors.name}
      />
      {showValidation && <WbErrorMessage message={errors.name} />}

      {selectedImage ? (
        <div className="wb-add-activity-wrp">
          <div className="wb-Edit-photo-sec">
            <div>
              <Image
                src={selectedImage}
                alt=""
                width={110}
                height={110}
                objectFit="cover"
              />
            </div>
            <div className="wb-edit-remove-button-wrap">
              <WbEditButton
                buttonText="Edit Photo"
                onClick={() => setShowImagePopup(true)}
              />
              <WbRemoveButton buttonText="Remove" onClick={handleImageRemove} />
            </div>
          </div>
        </div>
      ) : (
        <WbUpload onFileUpload={handleFileSelectWrapper} multiple={false} />
      )}

      <WbInput
        label="Address"
        value={address}
        onChange={e => handleInputChange('address', e.target.value)}
      />

      <div className="wb-row-2">
        <WbInput
          label="Phone Number"
          value={phone}
          onChange={e => handleInputChange('phone', e.target.value)}
        />

        <WbInput
          label="Email Address"
          value={email}
          onChange={e => handleInputChange('email', e.target.value)}
        />
      </div>

      <WbInput
        label="Website"
        value={website}
        onChange={e => handleInputChange('website', e.target.value)}
      />

      <div className="panel-sub-heading">
        <h4>Room Block Details</h4>
      </div>

      <div className="wb-row-2">
        <WbDateTimePicker
          label="Check-in Date"
          value={checkInDate}
          onChange={value => handleDateChange('checkInDate', value)}
        />

        <WbInput
          label="Block Code"
          value={blockCode}
          onChange={e => handleInputChange('blockCode', e.target.value)}
        />
      </div>

      <div className="wb-row-2">
        <WbDateTimePicker
          label="Check-out Date"
          value={checkOutDate}
          onChange={value => handleDateChange('checkOutDate', value)}
        />

        <WbInput
          label="Room Rate"
          value={roomRate}
          onChange={e => handleInputChange('roomRate', e.target.value)}
          error={errors.roomRate}
        />
      </div>
      {showValidation && <WbErrorMessage message={errors.roomRate} />}

      <WbTextarea
        label="Details"
        value={details}
        onChange={e => handleInputChange('details', e.target.value)}
        error={errors.details}
      />


      <WbDeletewithText
        text="Delete Hotel"
        className="ml-auto"
        onClick={() => onDeleteClick()}
      />
      {showImagePopup && (
        <ImagePopup
          imageSrc={selectedImage}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropCompleteWrapper}
        />
      )}
    </SidepanelEditorCard>
  );
}

WbAddHotel.propTypes = {
  address: PropTypes.string,
  blockCode: PropTypes.string,
  checkInDate: PropTypes.string,
  checkOutDate: PropTypes.string,
  details: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.object,
  name: PropTypes.string,
  phone: PropTypes.string,
  roomRate: PropTypes.string,
  website: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  onBlockCodeChange: PropTypes.func.isRequired,
  onCheckInDateChange: PropTypes.func.isRequired,
  onCheckOutDateChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  onRoomRateChange: PropTypes.func.isRequired,
  onWebsiteChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default WbAddHotel;
