import Image from "next/image";
import PropTypes from "prop-types";

function StoryView({ title, description, image ,className=''}) {
  return (
    <div className={`wb-story-view-card ${className}`}>
      <div className="wb-container">
        <div className="wb-cus-row">
          <div className="wb-story-view-card-item">
            <Image src={image} alt="" width={300} height={300} />
            <h4>{title}</h4>
            <h6>{description}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

StoryView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default StoryView;
