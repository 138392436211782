import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import { validateField } from "../../../../lib/weddingWebsiteValidations";
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbInput from "./shared/WbInput";
import WbTextarea from "./shared/WbTextarea";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbQnA({
  showValidation = false,
  title = "Add Question & Answer",
  handleAddQnA,
  handleUpdateQnA,
  qnaList = [],
  onDeleteClick,
}) {
  const [qnaListLocal, setQnaListLocal] = useState(() =>
    qnaList.map(qna => ({ ...qna, key: qna.key || `qna_${Date.now()}_${Math.random().toString(36).slice(2, 11)}` }))
  );

  useEffect(() => {
    if (qnaListLocal.length === 0) {
      const newQnA = { question: "", answer: "", key: `qna_${Date.now()}_${Math.random().toString(36).slice(2, 11)}` };
      setQnaListLocal([newQnA]);
      handleAddQnA(newQnA);
    }
  }, []);

  const handleQuestionChange = useCallback((key, value) => {
    setQnaListLocal(prevList => {
      const updatedList = prevList.map(item =>
        item.key === key ? { ...item, question: value } : item
      );
      return updatedList;
    });
  }, []);

  const handleAnswerChange = useCallback((key, value) => {
    setQnaListLocal(prevList => {
      const updatedList = prevList.map(item =>
        item.key === key ? { ...item, answer: value } : item
      );
      return updatedList;
    });
  }, []);

  const handleDeleteQnAItem = useCallback(
    key => {
      setQnaListLocal(prevList => {
        const updatedList = prevList.filter(item => item.key !== key);
        handleUpdateQnA(updatedList);
        return updatedList;
      });
    },
    [handleUpdateQnA]
  );

  return (
    <SidepanelEditorCard title={title} hideArrow>
      <div className="wb-qna-list">
        {qnaListLocal.map((qna) => (
          <QnAItem
            key={qna.key}
            qna={qna}
            onDeleteClick={() => onDeleteClick(qna.key)}
            handleQuestionChange={handleQuestionChange}
            handleAnswerChange={handleAnswerChange}
            handleDeleteQnAItem={handleDeleteQnAItem}
            handleUpdateQnA={() => handleUpdateQnA(qnaListLocal)}
            showValidation={showValidation}
          />
        ))}
      </div>
    </SidepanelEditorCard>
  );
}

function QnAItem({
  qna,
  handleQuestionChange,
  handleAnswerChange,
  handleUpdateQnA,
  onDeleteClick,
  showValidation,
}) {
  const [localQuestion, setLocalQuestion] = useState(qna.question);
  const [localAnswer, setLocalAnswer] = useState(qna.answer);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuestion, localAnswer, showValidation]);

  function validateAllFields() {
    const newErrors = {
      question: validateField("question", localQuestion, "QnA"),
      answer: validateField("answer", localAnswer, "QnA"),
    };
    setErrors(newErrors);
  }

  const handleOnchangeFn = (value, onChangeFunc, field) => {
    if (field === "question") {
      setLocalQuestion(value);
    } else if (field === "answer") {
      setLocalAnswer(value);
    }
    onChangeFunc(qna.key, value);
    setErrors({ ...errors, [field]: validateField(field, value, "QnA") });
    handleUpdateQnA();
  };

  return (
    <div className="wb-qna-item">
      <WbInput
        label={`Question`}
        value={localQuestion}
        onChange={e => handleOnchangeFn(e.target.value, handleQuestionChange, "question")}
        error={errors.question}
      />
      {showValidation && <WbErrorMessage message={errors.question} />}
      <WbTextarea
        label={`Answer Text`}
        value={localAnswer}
        onChange={e => handleOnchangeFn(e.target.value, handleAnswerChange, "answer")}
        error={errors.answer}
        disableFormatting={true}
      />
      {showValidation && <WbErrorMessage message={errors.answer} />}
      <WbDeletewithText
        text="Delete Q&A"
        className="ml-auto"
        onClick={onDeleteClick}
      />
    </div>
  );
}

WbQnA.propTypes = {
  title: PropTypes.string,
  showValidation: PropTypes.bool,
  handleAddQnA: PropTypes.func,
  handleUpdateQnA: PropTypes.func,
  qnaList: PropTypes.array,
  onDeleteClick: PropTypes.func,
};

QnAItem.propTypes = {
  qna: PropTypes.object,
  index: PropTypes.number,
  showValidation: PropTypes.bool,
  handleQuestionChange: PropTypes.func,
  handleAnswerChange: PropTypes.func,
  handleUpdateQnA: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default WbQnA;
