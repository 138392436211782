import Image from "next/image";
import PropTypes from "prop-types";

import TimeLineDivider from "./divider/TimeLineDivider";

function TimeLineView({ photoMemoryItems, className = "" }) {
  return (
    <div className={`wb-time-line-wrp ${className}`}>
      <div className="wb-container">
        {photoMemoryItems?.map((item, index) => (
          <>
            <div className="wb-timeline-content" key={item.key}>
              <h3>{item.date}</h3>
              <p>{item.description}</p>
              <div className="timeline-image ">
                <div className="fit-image">
                  <Image
                    src={item.image}
                    alt=""
                    width="100%"
                    height="100%"
                    layout="responsive"
                    style={{ height: "auto", width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <span>
              <TimeLineDivider />
            </span>
          </>
        ))}
      </div>
    </div>
  );
}

TimeLineView.propTypes = {
  photoMemoryItems: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
};

export default TimeLineView;
