import Image from "next/image";
import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";
import { validateField } from '../../../../lib/weddingWebsiteValidations';

import { getImageSrc, handleCropComplete, handleFileSelect } from '../../../../lib/common';
import SidepanelEditorCard from './shared/SidepanelEditorCard';
import WbEditButton from './shared/WbEditButton';
import WbInput from './shared/WbInput';
import WbRemoveButton from './shared/WbRemoveButton';
import WbTextarea from './shared/WbTextarea';
import WbUpload from './shared/WbUpload';
import ImagePopup from './ImagePopup';
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbAddActivity({
  onDeleteClick,
  name,
  onNameChange,
  address,
  onAddressChange,
  phone,
  onPhoneChange,
  email,
  onEmailChange,
  website,
  onWebsiteChange,
  details,
  onDetailsChange,
  onImageUpload,
  onImageRemove,
  image,
  showValidation = false
}) {
  const [localName, setLocalName] = useState(name);
  const [selectedImage, setSelectedImage] = useState(getImageSrc(image));
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  }, [localName, showValidation]);

  function validateAllFields() {
    const newErrors = {
      name: validateField('name', localName, 'Activity'),
    };
    setErrors(newErrors);
  }

  const handleBlur = (value, onChangeFunc, field) => {
    onChangeFunc(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Activity') });
  };

  function handleCropCompleteWrapper(croppedImage) {
    handleCropComplete(croppedImage, onImageUpload, setSelectedImage, setShowImagePopup);
  }

  const handleFileSelectWrapper = useCallback(event => {
    handleFileSelect(event, setSelectedImage, setShowImagePopup);
  }, []);

  function handleImageRemove() {
    onImageRemove();
    setSelectedImage(null);
  }

  return (
    <SidepanelEditorCard
      title="Add an Activity"
      onDeleteClick={onDeleteClick}
      hideArrow
    >
      <WbInput
        label="Name"
        value={localName}
        onChange={e => setLocalName(e.target.value)}
        onBlur={e => handleBlur(e.target.value, onNameChange, 'name')}
        error={errors.name}
      />
      {showValidation && <WbErrorMessage message={errors.name} />}

      {selectedImage ? (
        <div className="wb-add-activity-wrp">
          <div className="wb-Edit-photo-sec">
            <div>
              <Image src={selectedImage} alt="" width={110} height={110} objectFit="cover" />
            </div>
            <div className="wb-edit-remove-button-wrap">
              <WbEditButton
                buttonText="Edit Photo"
                onClick={() => setShowImagePopup(true)}
              />
              <WbRemoveButton buttonText="Remove" onClick={handleImageRemove} />
            </div>
          </div>
        </div>
      ) : (
        <WbUpload
          onFileUpload={handleFileSelectWrapper}
        />
      )}

      <div className="wb-add-activity-wrp">
        <WbInput
          label="Address"
          value={address}
          onChange={e => onAddressChange(e.target.value)}
        />
        <div className="wb-row-2">
          <WbInput
            label="Phone"
            value={phone}
            onChange={e => onPhoneChange(e.target.value)}
          />
          <WbInput
            label="Email"
            value={email}
            onChange={e => onEmailChange(e.target.value)}
          />
        </div>
        <WbInput
          label="Website"
          value={website}
          onChange={e => onWebsiteChange(e.target.value)}
        />
        <WbTextarea
          label="Details"
          value={details}
          onChange={e => onDetailsChange(e.target.value)}
        />
      </div>
      <WbDeletewithText
        text="Delete Activity"
        className="ml-auto"
        onClick={() => onDeleteClick()}
      />
      {showImagePopup && (
        <ImagePopup
          imageSrc={selectedImage}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropCompleteWrapper}
        />
      )}
    </SidepanelEditorCard>
  );
}

WbAddActivity.propTypes = {
  onDeleteClick: PropTypes.func,
  name: PropTypes.string,
  onNameChange: PropTypes.func,
  address: PropTypes.string,
  onAddressChange: PropTypes.func,
  phone: PropTypes.string,
  onPhoneChange: PropTypes.func,
  email: PropTypes.string,
  onEmailChange: PropTypes.func,
  website: PropTypes.string,
  onWebsiteChange: PropTypes.func,
  details: PropTypes.string,
  onDetailsChange: PropTypes.func,
  onImageUpload: PropTypes.func,
  onImageRemove: PropTypes.func,
  image: PropTypes.string,
  showValidation: PropTypes.bool,
};

export default WbAddActivity;
