import dayjs from "dayjs";
import Image from "next/image";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { getImageSrc } from "../../../../lib/common";
import { validateField } from "../../../../lib/weddingWebsiteValidations";
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbDragAndDropUpload from "./shared/WbDragAndDropUpload";
import { WbEditButtonWithoutText } from "./shared/WbEditButton";
import WbTextarea from "./shared/WbTextarea";
import WbUpload from "./shared/WbUpload";
import ImagePopup from "./ImagePopup";
import WbDateTimePicker from "./shared/WbDateTimePicker";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbAddPhotoMemory({
  onDeleteClick,
  photoMemoryItems = [],
  handleFileUpload,
  handleDeletePhotoMemory,
  showValidation = false,
}) {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [validatedItems, setValidatedItems] = useState([]);

  useEffect(() => {
    const newValidatedItems = photoMemoryItems.map(item => ({
      ...item,
      dateError: validateField("date", item.date, "PhotoMemory"),
      descriptionError: validateField(
        "description",
        item.description,
        "PhotoMemory"
      ),
      imageError: validateField("image", item.image, "PhotoMemory"),
    }));
    setValidatedItems(newValidatedItems);
  }, [photoMemoryItems]);

  function onFileUpload(files) {
    const newPhotoMemoryItems = [
      ...validatedItems,
      ...files.map(file => ({
        image: file,
        date: file.lastModified,
        description: "",
        dateError: "",
        descriptionError: "",
        imageError: validateField("image", file, "PhotoMemory"),
      })),
    ];

    handleFileUpload(newPhotoMemoryItems);
  }

  function handleCropComplete(croppedImage) {
    const updatedPhotoMemoryItems = validatedItems.map((item, index) => {
      if (index === selectedImageIndex) {
        return {
          ...item,
          image: croppedImage.blob,
          imageError: validateField("image", croppedImage.blob, "PhotoMemory"),
        };
      }
      return item;
    });
    handleFileUpload(updatedPhotoMemoryItems);
    setShowImagePopup(false);
  }

  function handleItemUpdate(index, field, value) {
    const error = validateField(field, value, "PhotoMemory");
    const updatedItems = validatedItems.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [field]: value,
          [`${field}Error`]: error,
        };
      }
      return item;
    });
    setValidatedItems(updatedItems);
    handleFileUpload(updatedItems);
  }

  function formatDate(date) {
    if (!date?.seconds || isNaN(date.seconds)) {
      return "";
    }
    const formattedDate = dayjs.unix(date.seconds).format("MM-DD-YYYY");
    return dayjs(formattedDate, "MM-DD-YYYY").isValid() ? formattedDate : "";
  }

  return (
    <SidepanelEditorCard
      title="Add Photo Timeline"
      onDeleteClick={onDeleteClick}
      hideArrow
    >
      {validatedItems.length ? null : (
        <WbDragAndDropUpload onFileUpload={onFileUpload} />
      )}

      {validatedItems.map((item, index) => {
        const {
          image,
          date,
          description,
          dateError,
          descriptionError,
          imageError,
        } = item;
        return (
          <div key={item.key}>
            <div className="wb-add-memories-wrp">
              <div className="wb-memory-photo">
                <div>
                  <Image
                    src={getImageSrc(image)}
                    alt=""
                    width={110}
                    height={110}
                  />
                  <div className="Wb-button-wrp">
                    <WbEditButtonWithoutText
                      onEditClick={() => {
                        setSelectedImageIndex(index);
                        setShowImagePopup(true);
                      }}
                    />
                  </div>
                </div>

                <WbDeletewithText
                  onClick={() => {
                    handleDeletePhotoMemory(
                      validatedItems.filter((_, i) => i !== index)
                    );
                  }}
                />
              </div>

              <div className="wb-memory-content">
                <WbDateTimePicker
                  label="Date of Memory"
                  name="date"
                  value={formatDate(date)}
                  onChange={newDate => handleItemUpdate(index, "date", newDate)}
                />
                {showValidation && <WbErrorMessage message={dateError} />}
                <WbTextarea
                  label="Description"
                  name="description"
                  value={description}
                  disableFormatting={true}
                  onChange={e =>
                    handleItemUpdate(index, "description", e.target.value)
                  }
                />
                {showValidation && <WbErrorMessage message={descriptionError} />}
              </div>
            </div>
            {showValidation && <WbErrorMessage message={imageError} />}
          </div>
        );
      })}

      {validatedItems.length ? (
        <WbUpload
          className="upload-link-btn"
          text="Add More Memories"
          onFileUpload={onFileUpload}
        />
      ) : null}

      {showImagePopup && (
        <ImagePopup
          imageSrc={getImageSrc(validatedItems[selectedImageIndex].image)}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropComplete}
        />
      )}
    </SidepanelEditorCard>
  );
}

WbAddPhotoMemory.propTypes = {
  onDeleteClick: PropTypes.func,
  photoMemoryItems: PropTypes.array,
  handleFileUpload: PropTypes.func,
  handleDeletePhotoMemory: PropTypes.func,
  showValidation: PropTypes.bool,
};

export default WbAddPhotoMemory;
