import Image from "next/image";
import PropTypes from "prop-types";
import { useState } from "react";

import { getImageSrc } from '../../../../lib/common';
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import WbDeleteButton from "./shared/WbDeleteButton";
import WbDragAndDropUpload from "./shared/WbDragAndDropUpload";
import { WbEditButtonWithoutText } from "./shared/WbEditButton";
import WbUpload from "./shared/WbUpload";
import ImagePopup from "./ImagePopup";

function WbAddPhoto({
  onDeleteClick,
  images = [],
  handleFileUpload,
  handleDeleteImageClick,
}) {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  function onFileUpload(files) {
    const newImages = files.map(file => ({ src: file }));
    handleFileUpload([...images, ...newImages]);
  }

  function handleCropComplete(croppedImage) {
    const updatedImages = [...images];
    updatedImages[selectedImageIndex] = croppedImage.blob;
    handleFileUpload(updatedImages);
    setShowImagePopup(false);
  }

  return (
    <SidepanelEditorCard
      title="Add Photos"
      onDeleteClick={onDeleteClick}
      hideArrow
    >
      {images.length ? null : (
        <WbDragAndDropUpload onFileUpload={onFileUpload} />
      )}

      <div className="wb-upload-photo-grid">
        <div className="wb-upload-photo-grid-row">
          {images.map((image, index) => (
            <div className="wb-upload-photo" key={image.key}>
              <Image src={getImageSrc(image.src)} alt="" width={110} height={110} />
              <div className="Wb-button-wrp">
                <WbDeleteButton
                  onDeleteClick={() => {
                    handleDeleteImageClick(
                      images.filter((_, i) => i !== index)
                    );
                  }}
                />
                <WbEditButtonWithoutText
                  onEditClick={() => {
                    setSelectedImageIndex(index);
                    setShowImagePopup(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {images.length ? (
          <WbUpload
            className="upload-link-btn"
            text="Add More Photos"
            onFileUpload={onFileUpload}
          />
        ) : null}
      </div>

      {showImagePopup && (
        <ImagePopup
          imageSrc={getImageSrc(images[selectedImageIndex].src)}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropComplete}
        />
      )}
    </SidepanelEditorCard>
  );
}

WbAddPhoto.propTypes = {
  onDeleteClick: PropTypes.func,
  images: PropTypes.array,
  handleFileUpload: PropTypes.func,
  handleDeleteImageClick: PropTypes.func,
};

export default WbAddPhoto;
