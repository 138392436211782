import PropTypes from "prop-types";

function GifView({ gif ,className='' }) {
  return (

      <div className={`wb-photos-gallery wb-media-view" ${className}`}>
         <div className="wb-container">
        <div className="wb-cus-row wb-col-1">
          <div className="gif-image">
            <img src={gif} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

GifView.propTypes = {
  gif: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default GifView;
