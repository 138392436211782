const ArrowRight = () => {
    return (
        <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.833374 6.91671L3.75004 4.00004L0.833374 1.08337" stroke="#A1A1A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowRight;

