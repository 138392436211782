import Image from "next/image";
import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";

import { getImageSrc, handleCropComplete, handleFileSelect } from '../../../../lib/common';
import { validateField } from '../../../../lib/weddingWebsiteValidations';
import SidepanelEditorCard from './shared/SidepanelEditorCard';
import WbEditButton from './shared/WbEditButton';
import WbInput from './shared/WbInput';
import WbRemoveButton from './shared/WbRemoveButton';
import WbTextarea from './shared/WbTextarea';
import WbUpload from './shared/WbUpload';
import ImagePopup from './ImagePopup';
import { WbDeletewithText } from "./shared/WbDeleteButton";
import WbErrorMessage from './shared/WbErrorMessage';

function WbAddTransport({
  onDeleteClick,
  showValidation = false,
  title,
  onTitleChange,
  address,
  onAddressChange,
  phone,
  onPhoneChange,
  email,
  onEmailChange,
  website,
  onWebsiteChange,
  details,
  onDetailsChange,
  onImageUpload,
  onImageRemove,
  image
}) {
  const [localTitle, setLocalTitle] = useState(title);
  const [selectedImage, setSelectedImage] = useState(getImageSrc(image));
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  }, [localTitle, showValidation]);

  function validateAllFields() {
    const newErrors = {
      title: validateField('title', localTitle, 'Transport'),
    };
    setErrors(newErrors);
  }

  const handleBlur = (value, onChangeFunc, field) => {
    onChangeFunc(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Transport') });
  };

  const handleCropCompleteWrapper = croppedImage => {
    handleCropComplete(croppedImage, onImageUpload, setSelectedImage, setShowImagePopup);
  };

  const handleFileSelectWrapper = useCallback(event => {
    handleFileSelect(event, setSelectedImage, setShowImagePopup);
  }, []);

  const handleImageRemove = () => {
    onImageRemove();
    setSelectedImage(null);
  };

  return (
    <SidepanelEditorCard
      title="Add a Transport"
      hideArrow
    >
      <WbInput
        label="Title"
        value={localTitle}
        onChange={e => setLocalTitle(e.target.value)}
        onBlur={e => handleBlur(e.target.value, onTitleChange, 'title')}
        error={errors.title}
      />
       {showValidation && <WbErrorMessage message={errors.title} />}

      {selectedImage ? (
        <div className="wb-add-transport-wrp">
          <div className="wb-Edit-photo-sec">
            <div>
              <Image src={selectedImage} alt="" width={110} height={110} objectFit="cover" />
            </div>
            <div className="wb-edit-remove-button-wrap">
              <WbEditButton
                buttonText="Edit Photo"
                onClick={() => setShowImagePopup(true)}
              />
              <WbRemoveButton buttonText="Remove" onClick={handleImageRemove} />
            </div>
          </div>
        </div>
      ) : (
        <WbUpload
          onFileUpload={handleFileSelectWrapper}
        />
      )}

      <div className="wb-add-transport-wrp">
        <WbInput
          label="Address"
          value={address}
          onChange={e => onAddressChange(e.target.value)}
        />
        <div className="wb-row-2">
          <WbInput
            label="Phone"
            value={phone}
            onChange={e => onPhoneChange(e.target.value)}
          />
          <WbInput
            label="Email"
            value={email}
            onChange={e => onEmailChange(e.target.value)}
          />
        </div>
        <WbInput
          label="Website"
          value={website}
          onChange={e => onWebsiteChange(e.target.value)}
        />
        <WbTextarea
          label="Details"
          value={details}
          onChange={e => onDetailsChange(e.target.value)}
        />
      </div>
      <WbDeletewithText
        text="Delete Transport"
        className="ml-auto"
        onClick={() => onDeleteClick()}
      />
      {showImagePopup && (
        <ImagePopup
          imageSrc={selectedImage}
          onClose={() => setShowImagePopup(false)}
          onCropComplete={handleCropCompleteWrapper}
        />
      )}
      {/* lsit of transport */}
      {/* <div className="wb-add-transport-content-wrp">
        <div>
          <h6>Transport</h6>
          <h4>2-20 Western Rd, London NW10 7LW, UK</h4>
        </div>
        <div className="wb-transport-photo-card">
          <div>
            <Image
              src="/images/wedding-websites/transport-image.png"
              alt=""
              width={110}
              height={110}
            />
          </div>
          <div className="wb-transport-content">
            <div className="wb-transport-content-card">
              <h6>Transport</h6>
              <h4>2-20 Western Rd, London NW10 7LW, UK</h4>
            </div>
            <div className="wb-transport-content-card">
              <h6>Description</h6>
              <h3>Anand</h3>
            </div>
          </div>
        </div>
      </div> */}
    </SidepanelEditorCard>
  );
}

WbAddTransport.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  address: PropTypes.string,
  onAddressChange: PropTypes.func,
  phone: PropTypes.string,
  onPhoneChange: PropTypes.func,
  email: PropTypes.string,
  onEmailChange: PropTypes.func,
  website: PropTypes.string,
  onWebsiteChange: PropTypes.func,
  details: PropTypes.string,
  onDetailsChange: PropTypes.func,
  onImageUpload: PropTypes.func,
  onImageRemove: PropTypes.func,
  image: PropTypes.string,
  showValidation: PropTypes.bool,
};

export default WbAddTransport;
