import Image from "next/image";
import PropTypes from "prop-types";

const noOfImagesPerSlice = 6;

function MediaView({ images , className=''}) {
  const noOfSlices = Math.ceil(images.length / noOfImagesPerSlice);
  const slices = Array.from({ length: noOfSlices }, (_, index) => index);

  return (
    <div className={`wb-container ${className}`}>
      {slices.map((_, index) => (
        <ImageGallery
          key={index}
          images={images.slice(
            index * noOfImagesPerSlice,
            (index + 1) * noOfImagesPerSlice
          )}
        />
      ))}
    </div>
  );
}

MediaView.propTypes = {
  className: PropTypes.string.isRequired
};

function ImageGallery({ images }) {
  return (
      <div className="wb-photos-gallery wb-media-view">
        <RowOne images={images.slice(0, 1)} />
        <RowTwo images={images.slice(1, 3)} />
        <RowThree images={images.slice(3, 6)} />
      </div>
  );
}

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
};


function RowOne({ images }) {
  return (
    <div className="wb-cus-row wb-col-1 ">
      <div className="gallery-image">
        {" "}
        <Image src={images[0].src} alt="" width={1320} height={618} />
      </div>
    </div>
  );
}

RowOne.propTypes = {
  images: PropTypes.array.isRequired,
};


function RowTwo({ images }) {
  if (!images.length) return null;

  return (
    <div className="wb-cus-row wb-col-2 ">
      {images.map((image, index) => (
        <div className="gallery-image" key={index}>
          {" "}
          <Image src={image.src} alt="" width={640} height={338} />
        </div>
      ))}
    </div>
  );
}

RowTwo.propTypes = {
  images: PropTypes.array.isRequired,
};

function RowThree({ images }) {
  if (!images.length) return null;

  return (
    <div className="wb-cus-row wb-col-3 ">
      {images.map((image, index) => (
        <div className="gallery-image" key={index}>
          {" "}
          <Image src={image.src} alt="" width={413} height={338} />
        </div>
      ))}
    </div>
  );
}

RowThree.propTypes = {
  images: PropTypes.array.isRequired,
};

MediaView.propTypes = {
  images: PropTypes.array.isRequired,
};

export default MediaView;
