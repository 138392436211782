import PropTypes from "prop-types";
import PlusIcon from "../../../../../components/Icons/PlusIcon";
import { handleFileUpload } from '../../../../../lib/common';

// class "upload-link-btn" to change the design look like link
const WbUpload = ({
  className = "",
  text = "Add a Photo",
  onFileUpload,
  accept = "image/jpeg, image/png, image/webp",
  multiple = true,
}) => {
  return (
    <div className={`wb-upload-image wb-form-field ${className}`}>
      <label className="wb-upload-button">
        <input
          type="file"
          id=""
          className="wb-upload-input form-control"
          accept={accept}
          onChange={e => handleFileUpload(e.target.files, accept, onFileUpload)}
          multiple={multiple}
        />
        <PlusIcon /> <span>{text}</span>
      </label>
    </div>
  );
};

WbUpload.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onFileUpload: PropTypes.func,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
};

export default WbUpload;
