import PropTypes from 'prop-types';

function WbRadio({
  id = '',
  name = '',
  value = '',
  checked = false,
  onChange,
  text = '',
  className = '',
  disabled = false,
}) {
  return (
    <div className={`wb-radio ${className}`}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
}

WbRadio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default WbRadio;
