const ImageIcon = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4561_1605)">
        <path d="M33.4517 45.5C33.1297 45.5 32.8 45.4606 32.4742 45.3763L2.83674 37.6119C0.80507 37.0644 -0.406263 35.0056 0.12657 33.0181L3.86599 19.3831C4.00399 18.8825 4.52915 18.5919 5.03899 18.7194C5.55074 18.8525 5.85357 19.3681 5.71749 19.8669L1.97999 33.4981C1.71357 34.4919 2.32307 35.5269 3.34082 35.8025L32.9667 43.5631C33.9845 43.8256 35.0348 43.2331 35.2993 42.2431L36.7962 36.8169C36.9342 36.3163 37.4594 36.0181 37.9712 36.155C38.4829 36.29 38.7838 36.8056 38.6477 37.3044L37.1527 42.7231C36.7023 44.3919 35.1498 45.5 33.4517 45.5Z" fill="#191919"/>
        <path d="M42.1665 34.25H11.4998C9.38575 34.25 7.6665 32.5681 7.6665 30.5V8C7.6665 5.93187 9.38575 4.25 11.4998 4.25H42.1665C44.2806 4.25 45.9998 5.93187 45.9998 8V30.5C45.9998 32.5681 44.2806 34.25 42.1665 34.25ZM11.4998 6.125C10.4438 6.125 9.58317 6.96688 9.58317 8V30.5C9.58317 31.5331 10.4438 32.375 11.4998 32.375H42.1665C43.2226 32.375 44.0832 31.5331 44.0832 30.5V8C44.0832 6.96688 43.2226 6.125 42.1665 6.125H11.4998Z" fill="#191919"/>
        <path d="M17.2498 17.375C15.1358 17.375 13.4165 15.6931 13.4165 13.625C13.4165 11.5569 15.1358 9.875 17.2498 9.875C19.3639 9.875 21.0832 11.5569 21.0832 13.625C21.0832 15.6931 19.3639 17.375 17.2498 17.375ZM17.2498 11.75C16.1938 11.75 15.3332 12.5919 15.3332 13.625C15.3332 14.6581 16.1938 15.5 17.2498 15.5C18.3059 15.5 19.1665 14.6581 19.1665 13.625C19.1665 12.5919 18.3059 11.75 17.2498 11.75Z" fill="#191919"/>
        <path d="M8.75911 32.2437C8.51377 32.2437 8.26844 32.1519 8.08061 31.97C7.70686 31.6044 7.70686 31.01 8.08061 30.6444L17.133 21.7887C18.2179 20.7275 20.1134 20.7275 21.1983 21.7887L23.8931 24.425L31.3528 15.6687C31.8952 15.0331 32.6925 14.6637 33.5416 14.6562H33.5627C34.4022 14.6562 35.1976 15.0125 35.7458 15.6369L45.7699 27.0781C46.1149 27.47 46.0689 28.0625 45.6664 28.4C45.2659 28.7375 44.6621 28.6944 44.3152 28.2987L34.291 16.8575C34.1051 16.6475 33.8483 16.5312 33.5627 16.5312C33.3634 16.5144 33.0126 16.6494 32.8267 16.8687L24.6943 26.4144C24.5218 26.6169 24.2707 26.7387 24.0004 26.75C23.7283 26.7687 23.4695 26.6637 23.2798 26.4762L19.8432 23.1144C19.4809 22.7619 18.8504 22.7619 18.4881 23.1144L9.43569 31.97C9.24977 32.1519 9.00444 32.2437 8.75911 32.2437Z" fill="#191919"/>
        </g>
        <defs>
        <clipPath id="clip0_4561_1605">
        <rect width="46" height="45" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>

    );
  };

  export default ImageIcon;
