import PropTypes from "prop-types";
import DateTime from "react-datetime";

function WbDateTimePicker({ label = "Date", placeholder = "MM/DD/YYYY", onChange,value="" }) {
    function handleDateChange(e) {
        onChange(e.toDate())
    }

    return (
        <div className="wb-input wb-datepicker">
            <label>{label}</label>
            <div className="wb-form-field">
                <DateTime
                    className="wb-datepicker"
                    dateFormat="MM-DD-YYYY"
                    closeOnSelect="true"
                    timeFormat={false}
                    inputProps={{
                        placeholder: placeholder,
                    }}
                    onChange={handleDateChange}
                    value={value}
                />
            </div>
        </div>
    );
}

WbDateTimePicker.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value :PropTypes.string,
};

export default WbDateTimePicker;
