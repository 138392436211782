import Image from "next/image";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { getImageSrc } from '../../../../lib/common';
import { validateField } from '../../../../lib/weddingWebsiteValidations';
import SidepanelEditorCard from "./shared/SidepanelEditorCard";
import WbDeleteButton from "./shared/WbDeleteButton";
import WbDragAndDropUpload from "./shared/WbDragAndDropUpload";
import WbErrorMessage from "./shared/WbErrorMessage";

function WbAddGif({
  onDeleteClick,
  gif,
  handleFileUpload,
  handleDeleteGif,
  showValidation = false
}) {
  const [localGif, setLocalGif] = useState(gif);
  const [error, setError] = useState("");

  useEffect(() => {
    validateGif();
  }, [localGif, showValidation]);

  function validateGif() {
    const newError = validateField('gif', localGif, 'Gif');
    setError(newError);
  }

  function onFileUpload(files) {
    const newGif = files[0];
    if (newGif) {
      setLocalGif(newGif);
      handleFileUpload(newGif);
    }
  }

  function onDeleteGif() {
    handleDeleteGif();
    setLocalGif(null);
  }

  return (
    <SidepanelEditorCard
      title="Add Gif"
      onDeleteClick={onDeleteClick}
      hideArrow
    >
      {localGif ? (
        <div className="wb-upload-photo-grid wb-upload-gif-grid">
          <div className="wb-upload-photo-grid-row">
            <div className="wb-upload-gif">
              <Image src={getImageSrc(localGif)} alt="" width={350} height={204} unoptimized objectFit="contain" />
              <WbDeleteButton onDeleteClick={onDeleteGif} />
            </div>
          </div>
        </div>
      ) : (
        <WbDragAndDropUpload
          onFileUpload={onFileUpload}
          multiple={false}
          accept="image/gif"
        />
      )}

      {showValidation && <WbErrorMessage message={error} />}
    </SidepanelEditorCard>
  );
}

WbAddGif.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  gif: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleFileUpload: PropTypes.func.isRequired,
  handleDeleteGif: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default WbAddGif;
