import PropTypes from "prop-types";

const WbRemoveButton = (props) => {
    return (
      <button className="wb-remove-btn wb-secondary-btn" onClick={props.onClick}>

         <p>{props.buttonText}</p>
      </button>
    );
  };

  WbRemoveButton.propTypes = {
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
  };

  export default WbRemoveButton;
