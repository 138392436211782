import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { validateField } from '../../../../../lib/weddingWebsiteValidations';

import SidepanelEditorCard from "../shared/SidepanelEditorCard";
import WbInput from "../shared/WbInput";
import WbTextarea from "../shared/WbTextarea";
import { WbDeletewithText } from "../shared/WbDeleteButton";

function WbaddLivestreamLink({
  onDeleteClick,
  title,
  description,
  buttonText,
  livestreamLink,
  onTitleChange,
  onDescriptionChange,
  onButtonTextChange,
  onLivestreamLinkChange,
  showValidation = false,
}) {
  const [localTitle, setLocalTitle] = useState(title);
  const [localButtonText, setLocalButtonText] = useState(buttonText);
  const [localLivestreamLink, setLocalLivestreamLink] = useState(livestreamLink);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateAllFields();
  }, [localTitle, localButtonText, localLivestreamLink, showValidation]);

  function validateAllFields() {
    const newErrors = {
      title: validateField('title', localTitle, 'Livestream'),
      buttonText: validateField('buttonText', localButtonText, 'Livestream'),
      livestreamLink: validateField('livestreamLink', localLivestreamLink, 'Livestream'),
    };
    setErrors(newErrors);
  }

  const handleBlur = (value, onChangeFunc, field) => {
    onChangeFunc(value);
    setErrors({ ...errors, [field]: validateField(field, value, 'Livestream') });
  };

  return (
    <SidepanelEditorCard
      title="Add Live Stream Link"
      hideArrow
    >
      <WbInput
        label="Title"
        value={localTitle}
        onChange={e => setLocalTitle(e.target.value)}
        onBlur={e => handleBlur(e.target.value, onTitleChange, 'title')}
        error={errors.title}
      />
      {showValidation && errors.title && <p className="error">{errors.title}</p>}

      <WbTextarea
        label="Short Description"
        value={description}
        onChange={e => onDescriptionChange(e.target.value)}
        disableFormatting={ true }
      />

      <WbInput
        label="Button Text"
        value={localButtonText}
        onChange={e => setLocalButtonText(e.target.value)}
        onBlur={e => handleBlur(e.target.value, onButtonTextChange, 'buttonText')}
        error={errors.buttonText}
      />
      {showValidation && errors.buttonText && <p className="error">{errors.buttonText}</p>}

      <WbInput
        label="Live Stream Link"
        value={localLivestreamLink}
        onChange={e => setLocalLivestreamLink(e.target.value)}
        onBlur={e => handleBlur(e.target.value, onLivestreamLinkChange, 'livestreamLink')}
        error={errors.livestreamLink}
      />
      {showValidation && errors.livestreamLink && <p className="error">{errors.livestreamLink}</p>}

      <WbDeletewithText
        text="Delete Live Stream Link"
        className="ml-auto"
        onClick={() => onDeleteClick()}
      />
    </SidepanelEditorCard>
  );
}

WbaddLivestreamLink.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  livestreamLink: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onButtonTextChange: PropTypes.func.isRequired,
  onLivestreamLinkChange: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default WbaddLivestreamLink;
