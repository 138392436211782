import dayjs from "dayjs";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import {
  getDocumentById,
  getDocumentsByCollection,
  getDocumentsByKeyAndValue,
} from "..";
import { COLLECTIONS } from "../../config/constants";
import { db } from "../../config/firebase/firebase";
import { post } from "../../req";
import { PageIds, TemplateIds, WebsiteEditorPages } from "./constants";

export const RefreshTypes = {
  REFRESH: "refresh",
  PAGE_NAV: "page-nav",
  PREVIEW: "preview",
};

export function refreshIframe({
  type = RefreshTypes.REFRESH,
  data = {},
  targetOrigin = "*",
} = {}) {
  const iframe = document.getElementById("template-preview");
  if (!iframe) {
    console.error("Template preview iframe not found");
    return;
  }

  iframe.contentWindow.postMessage({ type, data }, targetOrigin);
}

export async function saveSelectedTemplateDetails(data) {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const weddingWebsiteRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );

    await setDoc(weddingWebsiteRef, data, { merge: true });

    refreshIframe();
    return { success: true };
  } catch (error) {
    console.error("Error storing selected template and color:", error);
    return { success: false, error };
  }
}

export async function getWeddingWebsiteInfo(mainEventId) {
  try {
    console.log("🚀 CALLED getWeddingWebsiteInfo ", { mainEventId });
    const weddingWebsiteRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );
    const docSnap = await getDoc(weddingWebsiteRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching wedding website info:", error);
    return null;
  }
}

export const weddingWebsites = [
  {
    id: TemplateIds.ROSE_BUD,
    name: "Rose Bud",
    editorPreviewImagePng: "/images/dummy-template1.png",
    editorPreviewImageWbp: "/images/dummy-template1.webp",
    url: `${TemplateIds.ROSE_BUD}/${PageIds.HOME}`,
    description:
      "Rose Bud is a beautiful and elegant template that is perfect for a romantic wedding.",
    colors: [
      { colorCode: "#33434A", colorClass: "rose-bud-theme1" },
      { colorCode: "#570F00", colorClass: "rose-bud-theme2" },
      { colorCode: "#510038", colorClass: "rose-bud-theme3" },
      { colorCode: "#020030", colorClass: "rose-bud-theme4" },
    ],
  },
  {
    id: TemplateIds.TOLEDO,
    name: "Toledo",
    editorPreviewImagePng: "/images/dummy-template1.png",
    editorPreviewImageWbp: "/images/dummy-template1.webp",
    url: `${TemplateIds.TOLEDO}/${PageIds.HOME}`,
    description:
      "Toledo is a beautiful and elegant template that is perfect for a romantic wedding.",
    colors: [
      { colorCode: "#663853", colorClass: "toledo-theme1" },
      { colorCode: "#28003A", colorClass: "toledo-theme2" },
      { colorCode: "#003B58", colorClass: "toledo-theme3" },
      { colorCode: "#520063", colorClass: "toledo-theme4" },
    ],
  },
];

async function refreshStaticPages(customPageName) {
  await post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/api/revalidate?customPageName=${customPageName}`
  );
}

async function deleteOldPublishedDocumentAndPages(document) {
  await Promise.all([
    deleteDoc(doc(db, COLLECTIONS.PublishedWeddingWebsites, document.id)),
    refreshStaticPages(document.id),
  ]);
}

export async function publishWeddingWebsite() {
  const mainEventId = JSON.parse(localStorage.getItem("userData"))?.MainEventId;

  const unpublishedRef = doc(
    db,
    COLLECTIONS.MainEvents,
    mainEventId,
    COLLECTIONS.WeddingWebsite,
    "unpublished"
  );
  const unpublishedDoc = await getDoc(unpublishedRef);

  if (unpublishedDoc.exists()) {
    const publishedData = unpublishedDoc.data();
    const { customPageName } = publishedData;

    const lastPublishedDate = new Date();

    delete publishedData.createdAt;
    publishedData.updatedAt = lastPublishedDate;
    publishedData.mainEventId = mainEventId;

    // Delete all other published docs with the same mainEventId
    const publishedDocs = await getDocumentsByKeyAndValue(
      COLLECTIONS.PublishedWeddingWebsites,
      "mainEventId",
      mainEventId
    );
    const deletePromises = publishedDocs
      .filter(i => i.id !== customPageName)
      .map(deleteOldPublishedDocumentAndPages);
    await Promise.all(deletePromises);

    // Publish the new doc or update the existing one
    await Promise.all([
      setDoc(
        doc(db, COLLECTIONS.PublishedWeddingWebsites, customPageName),
        publishedData,
        { merge: true }
      ),
      updateDoc(unpublishedRef, {
        lastPublishedDate,
        updatedAt: lastPublishedDate,
      }),
    ]);

    // create new static pages
    await refreshStaticPages(customPageName);

    return true;
  }
}

export async function getPublishedWeddingWebsite(customPageName) {
  try {
    const data = await getDocumentById(
      COLLECTIONS.PublishedWeddingWebsites,
      customPageName
    );

    if (!data.id) {
      return null;
    }

    delete data.updatedAt;
    delete data.createdAt;
    delete data.lastPublishedDate;

    const weddingDate = data.home.weddingDate
      ? dayjs(data.home.weddingDate.toDate()).format("MMM DD, YYYY")
      : null;
    data.home.weddingDate = weddingDate;

    // DOTO convert all the dates to the format "MMM DD, YYYY" for all custom components
    // data.sections.forEach((section, index) => {
    //   if (section.type === WbSections.PhotoTimeline.id) {
    //     data.sectionState[index].forEach((item, index) => {
    //       item.date = item.date ? dayjs(item.date.toDate()).format("MMM DD, YYYY") : null;
    //     })
    //   }
    // })

    return data;
  } catch (error) {
    console.error("Error fetching published wedding website:", error);
    return null;
  }
}

export function getWeddingWebsiteCustomPaths(customPageName) {
  const pages = [
    WebsiteEditorPages.HOME.id,
    WebsiteEditorPages.OUR_STORY.id,
    WebsiteEditorPages.PHOTOS.id,
    WebsiteEditorPages.WEDDING_PARTY.id,
    WebsiteEditorPages.RSVP.id,
    WebsiteEditorPages.QA.id,
    WebsiteEditorPages.REGISTRY.id,
    WebsiteEditorPages.THINGS_TO_DO.id,
    WebsiteEditorPages.TRAVEL.id,
  ];

  return pages.map(page => `/${customPageName}/${page}`);
}

export async function getAllWeddingWebsiteCustomPageNames() {
  const data = await getDocumentsByCollection(
    COLLECTIONS.PublishedWeddingWebsites
  );

  return data
    .map(publishedWebsiteDoc =>
      getWeddingWebsiteCustomPaths(publishedWebsiteDoc.id)
    )
    .flat();
}
