import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTemplateId: "rose-bud",
  selectedColor: "",
  onTemplateDemo: false,
  pages: [],
  home: {
    firstName: "",
    partnerFirstName: "",
    lastName: "",
    partnerLastName: "",
  },
  selectedClass: "",
  selectedPage: null,
  customPageName: null,

  // for adding custom sections to the page
  sections: {},
  sectionState: {},

  toast: {
    type: "",
    message: "",
  },
};

export const weddingWebsiteSlice = createSlice({
  name: "weddingWebsite",
  initialState,
  reducers: {
    updateTemplateValues: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCurrentTemplate: (state, action) => {
      const { templateId, color } = action.payload;

      state.selectedTemplateId = templateId;
      state.selectedColor = color;

      const keys = [
        "customPageName",
        "home",
        "onTemplateDemo",
        "pages",
        "sections",
        "sectionState",
        "selectedPage",
      ];

      keys.forEach(key => {
        if (key in action.payload) {
          state[key] = action.payload[key];
        }
      });
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    unPublished: (state, action) => {
      state.unPublished = action.payload;
    },
    addSection: (state, action) => {
      if (!state.sections[state.selectedPage]) {
        state.sections[state.selectedPage] = [];
        state.sectionState[state.selectedPage] = [];
      }
      state.sections[state.selectedPage].push(action.payload);
      state.sectionState[state.selectedPage].push({});
    },
    removeSection: (state, action) => {
      state.sections[state.selectedPage].splice(action.payload, 1);
      state.sectionState[state.selectedPage].splice(action.payload, 1);
    },
    setSectionState: (state, action) => {
      const { index, data } = action.payload;

      const oldState = state.sectionState[state.selectedPage][index] || {};

      state.sectionState[state.selectedPage][index] = {
        ...oldState,
        ...data,
      };
    },
    updatePageSections: (state, action) => {
      const { sections, sectionState } = action.payload;

      const page = state.selectedPage;
      state.sections[page] = sections;
      state.sectionState[page] = sectionState;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setPageVisibility: (state, action) => {
      const { id, visible } = action.payload;
      const page = state.pages.find(page => page.id === id);
      page.visible = visible;
    },
    setToast: (state, action) => {
      state.toast = action.payload;
    },
  },
});

export const {
  updateTemplateValues,
  setCurrentTemplate,
  setSelectedPage,
  unPublishedTemplate,
  addSection,
  removeSection,
  setSectionState,
  updatePageSections,
  setPages,
  setPageVisibility,
  setToast,
} = weddingWebsiteSlice.actions;

export default weddingWebsiteSlice.reducer;
