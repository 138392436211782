
import React from 'react'

function PlaceholderIcon() {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9671 12.2214V1.77794C14.9671 0.912769 14.1956 0.211426 13.2439 0.211426H1.75613C0.804437 0.211426 0.032959 0.912769 0.032959 1.77794V12.2214C0.032959 13.0866 0.804437 13.7879 1.75613 13.7879H13.2439C14.1956 13.7879 14.9671 13.0866 14.9671 12.2214ZM10.5419 6.6259C10.5408 6.62691 10.5397 6.62792 10.5386 6.62893L8.07442 8.87428L5.61029 6.63415C5.38624 6.43168 5.02445 6.43168 4.8004 6.63415L1.18174 9.91862V1.77794C1.18174 1.48954 1.43889 1.25577 1.75613 1.25577H13.2439C13.5612 1.25577 13.8183 1.48954 13.8183 1.77794V8.87428L11.3542 6.63415C11.1324 6.42796 10.7687 6.42427 10.5419 6.6259Z" fill="#636363" />
            <path d="M8.07473 2.82227C7.12304 2.82227 6.35156 3.52361 6.35156 4.38878C6.35156 5.25396 7.12304 5.9553 8.07473 5.9553C9.02643 5.9553 9.7979 5.25396 9.7979 4.38878C9.7979 3.52361 9.02643 2.82227 8.07473 2.82227ZM8.07473 4.91096C7.75749 4.91096 7.50034 4.67719 7.50034 4.38878C7.50034 4.10038 7.75749 3.86661 8.07473 3.86661C8.39198 3.86661 8.64912 4.10038 8.64912 4.38878C8.64912 4.67719 8.39198 4.91096 8.07473 4.91096Z" fill="#636363" />
        </svg>
    )
}

export default PlaceholderIcon