import React from 'react'

function RotateIcon() {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0583 4.58491C14.0637 4.59479 14.0687 4.60497 14.0732 4.61555C14.629 5.92835 14.709 7.34717 14.3686 8.65692C14.0284 9.9662 13.2686 11.1662 12.1445 12.0423C10.7723 13.1121 9.09574 13.5244 7.49507 13.326C5.89447 13.1277 4.36925 12.319 3.29942 10.9466C3.18683 10.8022 3.21261 10.5936 3.35709 10.4811C3.50157 10.3685 3.71007 10.3942 3.82274 10.5387C4.77982 11.7667 6.14458 12.4903 7.57663 12.6678C9.00861 12.8452 10.5088 12.4763 11.7366 11.5191C12.7435 10.7341 13.4242 9.65999 13.7283 8.48901C14.0305 7.32633 13.9616 6.0668 13.4732 4.89833C13.4658 4.88501 13.4591 4.87114 13.4533 4.85656C12.9846 3.66967 12.1467 2.71408 11.1093 2.09227C10.0716 1.47007 8.83342 1.18079 7.56496 1.32645C6.01827 1.50423 4.68956 2.29231 3.79242 3.42239C3.03365 4.37798 2.58312 5.57859 2.56949 6.8617L3.10644 6.34778L3.17131 6.28564L3.17194 6.28502L3.17257 6.28447L3.17327 6.28392L3.17382 6.28329L3.17453 6.28275L3.17515 6.28212L3.1757 6.28149L3.34784 6.11664C3.34855 6.11601 3.35756 6.10778 3.35795 6.10723C3.48911 5.98171 3.70059 5.99237 3.82462 6.12494C3.94858 6.25736 3.94246 6.46586 3.81169 6.59106L3.63516 6.76015L3.56512 6.82699L2.54857 7.80013C2.52036 7.84032 2.48338 7.87425 2.43997 7.89878C2.38684 7.93591 2.32518 7.9555 2.26297 7.95793L2.2614 7.95801L2.25498 7.95825L2.25419 7.95825C2.19621 7.95942 2.13815 7.9454 2.08683 7.91617L2.0866 7.91609L2.08041 7.91249L2.07962 7.91194C2.05447 7.89705 2.03097 7.87864 2.00989 7.8567L2.00981 7.85662C2.00346 7.85004 1.99751 7.84322 1.99187 7.83617L1.84065 7.67821L1.83986 7.67751L1.83947 7.67688L1.83877 7.67625L1.8383 7.67563L1.83767 7.675L1.83696 7.67437L1.83649 7.67375L1.77428 7.60879L0.73438 6.52251C0.609328 6.39189 0.614029 6.17861 0.744644 6.05364L0.744644 6.05356C0.875415 5.92843 1.08736 5.9318 1.21366 6.06375L1.90725 6.78836C1.93694 5.3791 2.43785 4.06183 3.27294 3.01002C4.27586 1.74673 5.76057 0.865968 7.48919 0.667343C8.90511 0.504682 10.2881 0.828202 11.4483 1.52374C12.6005 2.21458 13.5326 3.27227 14.0583 4.58491Z" fill="#191919" />
        </svg>
    )
}

export default RotateIcon