import WbaddLivestreamLink from "../../pages/wedding-website/template-editor/_editor-components/live-stream/WbaddLivestreamLink";
import WbAddGif from "../../pages/wedding-website/template-editor/_editor-components/WbAddGif";
import WbAddPerson from "../../pages/wedding-website/template-editor/_editor-components/WbAddPerson";
import WbAddPhoto from "../../pages/wedding-website/template-editor/_editor-components/WbAddPhoto";
import WbAddPhotoMemory from "../../pages/wedding-website/template-editor/_editor-components/WbAddPhotoMemory";
import WbAddStory from "../../pages/wedding-website/template-editor/_editor-components/WbAddStory";
import WbAddTitle from "../../pages/wedding-website/template-editor/_editor-components/WbAddTitle";
import WbAddTransport from "../../pages/wedding-website/template-editor/_editor-components/WbAddTransport";
import WbQnA from "../../pages/wedding-website/template-editor/_editor-components/WbQnA";
import WbTextBlock from "../../pages/wedding-website/template-editor/_editor-components/WbTextBlock";
import GifView from "../../pages/wedding-website/wedding-templates/_template-components/shared/GifView";
import LiveStreamView from "../../pages/wedding-website/wedding-templates/_template-components/shared/LiveStreamView";
import MediaView from "../../pages/wedding-website/wedding-templates/_template-components/shared/MediaView";
import StoryView from "../../pages/wedding-website/wedding-templates/_template-components/shared/StoryView";
import TextBlockView from "../../pages/wedding-website/wedding-templates/_template-components/shared/TextBlockView";
import TimeLineView from "../../pages/wedding-website/wedding-templates/_template-components/shared/TimeLineView";
import TitleView from "../../pages/wedding-website/wedding-templates/_template-components/shared/TitleView";
import { setSectionState } from "../../store/weddingWebsite/weddingWebsite";
import WbAddActivity from "../../pages/wedding-website/template-editor/_editor-components/WbAddActivity";
import WbAddHotel from "../../pages/wedding-website/template-editor/_editor-components/WbAddHotel";

export const WbSections = {
  Title: {
    id: "Title",
    renderComponent: TitleView,
    editor: WbAddTitle,
    handlers: (dispatch, index) => {
      return {
        onTitleTypeChange: titleType =>
          dispatch(setSectionState({ index, data: { titleType } })),
        onTitleTextChange: titleText =>
          dispatch(setSectionState({ index, data: { titleText } })),
      };
    },
  },

  TextBlock: {
    id: "TextBlock",
    renderComponent: TextBlockView,
    editor: WbTextBlock,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: titleText =>
          dispatch(setSectionState({ index, data: { titleText } })),
        onBodyChange: bodyText =>
          dispatch(setSectionState({ index, data: { bodyText } })),
      };
    },
  },

  Photo: {
    id: "Photo",
    renderComponent: MediaView,
    editor: WbAddPhoto,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: images => {

          dispatch(setSectionState({ index, data: { images } }));
        },
        handleDeleteImageClick: images => {
          dispatch(setSectionState({ index, data: { images } }));
        },
      };
    },
  },

  GIf: {
    id: "GIf",
    renderComponent: GifView,
    editor: WbAddGif,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: gif => {
          dispatch(setSectionState({ index, data: { gif } }));
        },
        handleDeleteGif: () => {
          dispatch(setSectionState({ index, data: { gif: null } }));
        },
      };
    },
  },

  PhotoTimeline: {
    id: "PhotoTimeline",
    renderComponent: TimeLineView,
    editor: WbAddPhotoMemory,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: photoMemoryItems => {
          dispatch(setSectionState({ index, data: { photoMemoryItems } }));
        },
        handleDeletePhotoMemory: photoMemoryItems => {
          dispatch(setSectionState({ index, data: { photoMemoryItems } }));
        },
      };
    },
  },

  LiveStream: {
    id: "LiveStream",
    renderComponent: LiveStreamView,
    editor: WbaddLivestreamLink,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onButtonTextChange: buttonText =>
          dispatch(setSectionState({ index, data: { buttonText } })),
        onLivestreamLinkChange: livestreamLink =>
          dispatch(setSectionState({ index, data: { livestreamLink } })),
      };
    },
  },

  Story: {
    id: "Story",
    renderComponent: StoryView,
    editor: WbAddStory,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },

  Person: {
    id: "Person",
    renderComponent: "",
    editor: WbAddPerson,
    handlers: (dispatch, index) => {
      return {
        handleDeletePerson: () => {
          dispatch(setSectionState({ index, data: { person: null } }));
        },
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onRoleChange: role =>
          dispatch(setSectionState({ index, data: { role } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        handleImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  QnA: {
    id: "QnA",
    renderComponent: "",
    editor: WbQnA,
    handlers: (dispatch, index) => {
      return {
        handleAddQnA: () => {
          dispatch(
            setSectionState({
              index,
              data: prevData => ({
                qnaList: [
                  ...(prevData.qnaList || []),
                  { question: "", answer: "" },
                ],
              }),
            })
          );
        },
        handleUpdateQnA: qnaList => {
          dispatch(setSectionState({ index, data: { qnaList } }));
        },

        handleDeleteQnA: qnaIndex => {
          dispatch(
            setSectionState({
              index,
              data: prevData => ({
                qnaList: prevData.qnaList.filter((_, i) => i !== qnaIndex),
              }),
            })
          );
        },
      };
    },
  },
  Transport: {
    id: "Transport",
    renderComponent: "",
    editor: WbAddTransport,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  Activity: {
    id: "Activity",
    renderComponent: "",
    editor: WbAddActivity,
    handlers: (dispatch, index) => {
      return {
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  Hotel: {
    id: "Hotel",
    renderComponent: null,
    editor: WbAddHotel,
    handlers: (dispatch, index) => {
      return {
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onBlockCodeChange: blockCode =>
          dispatch(setSectionState({ index, data: { blockCode } })),
        onCheckInDateChange: checkInDate =>
          dispatch(setSectionState({ index, data: { checkInDate } })),
        onCheckOutDateChange: checkOutDate =>
          dispatch(setSectionState({ index, data: { checkOutDate } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onRoomRateChange: roomRate =>
          dispatch(setSectionState({ index, data: { roomRate } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
      };
    },
  },
};

export const PageIds = {
  HOME: "home",
  OUR_STORY: "our-story",
  PHOTOS: "photos",
  WEDDING_PARTY: "wedding-party",
  RSVP: "rsvp",
  PRIVACY_URL: "privacy-url",
  QA: "qa",
  REGISTRY: "registry",
  THINGS_TO_DO: "things-to-do",
  TRAVEL: "travel",
};

export const TemplateIds = {
  ROSE_BUD: "rose-bud",
  TOLEDO: "toledo",
};

export const PageIdToText = {
  [PageIds.HOME]: "Home",
  [PageIds.OUR_STORY]: "Our Story",
  [PageIds.PHOTOS]: "Photos",
  [PageIds.WEDDING_PARTY]: "Wedding Party",
  [PageIds.RSVP]: "RSVP",
  [PageIds.PRIVACY_URL]: "Privacy & URL",
  [PageIds.QA]: "Q & A",
  [PageIds.REGISTRY]: "Registry",
  [PageIds.THINGS_TO_DO]: "Things to do",
  [PageIds.TRAVEL]: "Travel",
};

export const WebsiteEditorPages = {
  HOME: { text: "Home", id: PageIds.HOME },
  OUR_STORY: { text: "Our Story", id: PageIds.OUR_STORY },
  PHOTOS: { text: "Photos", id: PageIds.PHOTOS },
  WEDDING_PARTY: { text: "Wedding Party", id: PageIds.WEDDING_PARTY },
  RSVP: { text: "RSVP", id: PageIds.RSVP },
  PRIVACY_URL: { text: "Privacy & URL", id: PageIds.PRIVACY_URL },
  QA: { text: "Q & A", id: PageIds.QA },
  REGISTRY: { text: "Registry", id: PageIds.REGISTRY },
  THINGS_TO_DO: { text: "Things to do", id: PageIds.THINGS_TO_DO },
  TRAVEL: { text: "Travel", id: PageIds.TRAVEL },
  ADD_NEW_PAGE: { text: "Add New Page", id: "add-new-page" },
  CUSTOME_SECTION: { text: "Custom Section", id: "custom-section" },
};

export const WbPages = [
  {
    id: WebsiteEditorPages.HOME.id,
    name: WebsiteEditorPages.HOME.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.OUR_STORY.id,
    name: WebsiteEditorPages.OUR_STORY.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.PHOTOS.id,
    name: WebsiteEditorPages.PHOTOS.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.WEDDING_PARTY.id,
    name: WebsiteEditorPages.WEDDING_PARTY.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.RSVP.id,
    name: WebsiteEditorPages.RSVP.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.QA.id,
    name: WebsiteEditorPages.QA.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.REGISTRY.id,
    name: WebsiteEditorPages.REGISTRY.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.THINGS_TO_DO.id,
    name: WebsiteEditorPages.THINGS_TO_DO.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.TRAVEL.id,
    name: WebsiteEditorPages.TRAVEL.text,
    visible: true,
  },
  {
    id: WebsiteEditorPages.PRIVACY_URL.id,
    name: WebsiteEditorPages.PRIVACY_URL.text,
    visible: true,
  },
];