import PropTypes from 'prop-types';

function TextBlockView({ titleText, bodyText, className = '' }) {
    return (
        <div className={`wb-title-block ${className}`}>
            <h3>{titleText}</h3>
            <p>{bodyText}</p>
        </div>
    );
}

TextBlockView.propTypes = {
    titleText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
}

export default TextBlockView;