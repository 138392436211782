import PropTypes from "prop-types";

function LiveStreamView({ title, description, buttonText, livestreamLink, className='' }) {
  return (
    <div className={`wb-live-stream-view ${className}`}>
      <h4>{title}</h4>
      <p>{description}</p>
      <a href={livestreamLink} target="_blank" rel="noopener noreferrer">
        <button className="wb-view-btn">{buttonText}</button>
      </a>
    </div>
  );
}

LiveStreamView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  livestreamLink: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default LiveStreamView;
