import PropTypes from "prop-types";
import { LARGE, SMALL } from '../../../template-editor/_editor-components/WbAddTitle';

function TitleView({ titleText, titleType }) {
  const className = titleType === LARGE ? "wb-large-tile" : "wb-small-tile";

  return (
    <div className={className}>
      {titleType === LARGE ? <h2>{titleText}</h2> : <h4>{titleText}</h4>}{" "}
    </div>
  );
}

TitleView.propTypes = {
  titleText: PropTypes.string.isRequired,
  titleType: PropTypes.oneOf([LARGE, SMALL]).isRequired,
};

export default TitleView;
