export function validateField(fieldName, value, pageType) {
  switch (pageType) {
    case "Title":
      return validateTitlePage(fieldName, value);
    case "Story":
      return validateStoryPage(fieldName, value);
    case "Gif":
      return validateGifPage(fieldName, value);
    case "PhotoMemory":
      return validatePhotoMemoryPage(fieldName, value);
    case "Hotel":
      return validateHotelPage(fieldName, value);
    case "QnA":
      return validateQnAPage(fieldName, value);
    case "Person":
      return validatePersonPage(fieldName, value);
    case "Transport":
      return validateTransportPage(fieldName, value);
    case "Activity":
      return validateActivityPage(fieldName, value);
    case "Livestream":
      return validateLivestreamPage(fieldName, value);
    case "TextBlock":
      return validateTextBlockPage(fieldName, value);
    case "BasicInfo":
      return validateBasicInfoPage(fieldName, value);
    case "HomeCoverText":
      return validateHomeCoverTextPage(fieldName, value);
    case "EventInformation":
      return validateEventInformationPage(fieldName, value);
  }
}

function validateNotEmpty(value, fieldName) {
  const message = `${fieldName} cannot be empty`;
  if (!value) {
    return message;
  }

  if (
    fieldName != "Date" &&
    typeof value === "string" &&
    value.trim() === ""
  ) {
    return message;
  }

  return "";
}

function validateTitlePage(fieldName, value) {
  if (fieldName === "titleText") {
    return validateNotEmpty(value, "Title text");
  }
  return "";
}

function validateStoryPage(fieldName, value) {
  switch (fieldName) {
    case "title": {
      const emptyTitleError = validateNotEmpty(value, "Title text");
      if (emptyTitleError) return emptyTitleError;
      if (value) return value.length > 120 ? "Title must be 120 characters or less" : "";
    }
    case "description": {
      return validateNotEmpty(value, "Story");
    }
    default: {
      return "";
    }
  }
}

function validateGifPage(fieldName, value) {
  if (fieldName === "gif") {
    if (typeof value === "string") {
      return "";
    }
    if (!value) {
      return "Please upload a GIF";
    }
    if (value.type !== "image/gif") {
      return "Invalid file format. Please upload a GIF";
    }
    if (value.size > 2 * 1024 * 1024) {
      return "The file size is too large. The allowed maximum size is 2MB";
    }
    return "";
  }
  return "";
}

function validatePhotoMemoryPage(fieldName, value) {
  switch (fieldName) {
    case "date":
      return validateNotEmpty(value, "Date");
    case "description":
      return validateNotEmpty(value, "Description");
    case "image":
      if (typeof value === "string") {
        return true;
      }
      if (!value) return "Please upload an image";
      if (!value.type.startsWith("image/"))
        return "Invalid file format. Please upload an image";
      if (value.size > 1 * 1024 * 1024)
        return "The file size is too large. The allowed maximum size is 5MB";
      return "";
    default:
      return "";
  }
}

function validateHotelPage(fieldName, value) {
  if (fieldName === "name") {
    return validateNotEmpty(value, "Hotel Name");
  }
  return "";
}

function validateQnAPage(fieldName, value) {
  switch (fieldName) {
    case "question":
      return validateNotEmpty(value, "Question");
    case "answer":
      return validateNotEmpty(value, "Answer");
    default:
      return "";
  }
}

function validatePersonPage(fieldName, value) {
  switch (fieldName) {
    case "name": {
      const emptyNameError = validateNotEmpty(value, "Name");
      if (emptyNameError) return emptyNameError;
      return value?.length > 120 ? "Name must be 120 characters or less" : "";
    }
    case "role": {
      const emptyRoleError = validateNotEmpty(value, "Role");
      if (emptyRoleError) return emptyRoleError;
      return value?.length > 120 ? "Role must be 120 characters or less" : "";
    }
    default: {
      return "";
    }
  }
}

function validateTransportPage(fieldName, value) {
  if (fieldName === "title") {
    const emptyTitleError = validateNotEmpty(value, "Title");
    if (emptyTitleError) {
      return emptyTitleError;
    }
    return value?.length > 120 ? "Title must be 120 characters or less" : "";
  }
  return "";
}

function validateActivityPage(fieldName, value) {
  if (fieldName === "name") {
    const emptyNameError = validateNotEmpty(value, "Name");
    if (emptyNameError) return emptyNameError;
    return value?.length > 120 ? "Name must be 120 characters or less" : "";
  }
  return "";
}

function validateLivestreamPage(fieldName, value) {
  switch (fieldName) {
    case "title": {
      return validateNotEmpty(value, "Title");
    }
    case "buttonText": {
      return validateNotEmpty(value, "Button Text");
    }
    case "livestreamLink": {
      const emptyLinkError = validateNotEmpty(value, "Live Stream Link");
      if (emptyLinkError) {
        return emptyLinkError;
      }
      if (value) {
        return validateUrl(value);
      }
    }
    default: {
      return "";
    }
  }
}

function validateUrl(value) {
  const urlPattern = /^(https?:\/\/)?(www\.)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[^\s]*)?$/i;
  return urlPattern.test(value) ? "" : "Please enter a valid URL";
}

function validateTextBlockPage(fieldName, value) {
  switch (fieldName) {
    case "titleText": {
      const emptyTitleError = validateNotEmpty(value, "Title text");
      if (emptyTitleError) {
        return emptyTitleError;
      }
      if (value) {
        return value.length > 120 ? "Title must be 120 characters or less" : "";
      }
    }
    case "bodyText": {
      return validateNotEmpty(value, "Body text");
    }
    default: {
      return "";
    }
  }
}

function validateBasicInfoPage(fieldName, value) {
  switch (fieldName) {
    case "partnerFirstName":
        return validateNotEmpty(value, "Partner first name");
    case "partnerLastName":
        return validateNotEmpty(value, "Partner last name");
    case "firstName":
        return validateNotEmpty(value, 'first name');
    case "lastName":
      return validateNotEmpty(value, 'last name');
    case "weddingDate":
      return value ? "" : "Wedding date is required";
    case "weddingLocation":
      return validateNotEmpty(value, "Wedding location");
    case "announcement":
      return ""; // Announcement can be empty
    default:
      return "";
  }
}

function validateHomeCoverTextPage(fieldName, value) {
  switch (fieldName) {
    case "coverTitle":
      return validateNotEmpty(value, "Cover title");
    case "coverText":
      return validateNotEmpty(value, "Cover text");
    default:
      return "";
  }
}

function validateEventInformationPage(fieldName, value) {
  switch (fieldName) {
    case "eventName":
      return validateNotEmpty(value, "Event name");
    case "date":
      return validateNotEmpty(value, "Date");
    case "startTime":
      return validateNotEmpty(value, "Start time");
    case "endTime":
      return validateNotEmpty(value, "End time");
    case "venueName":
      return validateNotEmpty(value, "Venue name");
    case "attire":
      return ""; // Attire can be optional
    case "description":
      return ""; // Description can be optional
    default:
      return "";
  }
}

export function validateAllFields(data, section) {
  const errors = {};

  Object.keys(data).forEach(field => {
    const error = validateField(field, data[field], section);
    if (error) {
      errors[field] = error;
    }
  });

  return errors;
}
