import PropTypes from "prop-types";

const WbModalLayout = ({children, className}) => {
  return (
    <div className={`wb-overlay show ${className}`}>
      <div className="wb-popup">
        {children}
      </div>
    </div>
  );
}

WbModalLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
export default WbModalLayout;